export const endpoint = 'https://begemot.ai/api/';

export type TokenResponse = {
  token: string;
};

export const login = async (email: string, password: string): Promise<TokenResponse> => {
  return await $fetch(endpoint + 'login', {
    method: 'POST',
    body: {
      email,
      password,
    },
  });
};

export const loginByYandex = async (token: string): Promise<TokenResponse> => {
  return await $fetch(endpoint + 'login_yandex', {
    method: 'POST',
    body: { token },
  });
};
export const loginByVK = async (payload: string): Promise<TokenResponse> => {
  return await $fetch(endpoint + 'login_vk', {
    method: 'POST',
    body: { payload },
  });
};

export const register = async (name: string, email: string, password: string, password_confirmation: string): Promise<TokenResponse> => {
  return await $fetch(endpoint + 'register', {
    method: 'POST',

    body: {
      name,
      email,
      password,
      password_confirmation,
    },
  });
};

// export const getToken = async(jwtToken: string, code: string): Promise<TokenResponse> => {
//     return await $fetch(endpoint + 'auth/sms/getToken', {
//         method: 'POST',
//         body: {
//             jwt_token: jwtToken,
//             code,
//         },
//     });
// };+ `register?name=${name}&email=${email}&password=${password}&password_confirmation=${password}`
