import debounce from 'lodash.debounce';
import { defineStore } from 'pinia';
import { endpoint } from '~/api/auth';
import { authFetch } from '~/api/utils';

type Event = {
  event_name: string;
  query?: string;
  project_id?: number;
  ts?: number;
  amount?: number;
  screen?: string;
  services?: string[];
};

export const useAnalyticsStore = defineStore('analytics', () => {
  const events = ref<Event[]>([]);
  const alreadySent = ref<boolean>(false);

  const sendEvents = () => {
    if (alreadySent.value) {
      return;
    }
    const eventsCopy = [...events.value];
    events.value = [];
    alreadySent.value = true;
    authFetch(`${endpoint}logEvent`, {
      method: 'POST',
      body: {
        events: eventsCopy,
      },
    }).finally(() => {
      alreadySent.value = false;
    });
  };

  const sendEventsDebounced = debounce(sendEvents, 10000, { leading: true });
  const addEvent = (event: Event) => {
    event.ts = new Date().getTime();
    console.log('addEvent', event.event_name);
    events.value = [...events.value, event];
    sendEventsDebounced();
  };

  const analyticsPageOpen = (screen: string) => {
    return addEvent({
      event_name: 'openPage',
      screen,
    });
  };

  const analyticsProjectPageOpen = (projectId: number) => {
    return addEvent({
      event_name: 'openProjectPage',
      project_id: projectId,
    });
  };

  const analyticsClickActionFloatBlock = (projectId: number) => {
    return addEvent({
      event_name: 'clickActionFloatBlock',
      project_id: projectId,
    });
  };

  const analyticsToggleServices = (projectId: number, services: string[]) => {
    return addEvent({
      event_name: 'toggleServices',
      project_id: projectId,
      services,
    });
  };

  const analyticsEnableService = (projectId: number, service: string) => {
    return addEvent({
      event_name: 'enableService',
      project_id: projectId,
      services: [service],
    });
  };

  const analyticsDisableService = (projectId: number, service: string) => {
    return addEvent({
      event_name: 'disableService',
      project_id: projectId,
      services: [service],
    });
  };

  const analyticsEnterPrompt = (query: string) => {
    return addEvent({
      event_name: 'enterPrompt',
      query,
    });
  };

  const analyticsSubmitPrompt = (query: string) => {
    return addEvent({
      event_name: 'submitPrompt',
      query,
    });
  };

  const analyticsSubmitPayForm = (projectId: number, amount: number, services: string[]) => {
    return addEvent({
      event_name: 'submitPayForm',
      project_id: projectId,
      amount,
      services,
    });
  };

  return {
    events,
    addEvent,
    analyticsPageOpen,
    analyticsProjectPageOpen,
    analyticsClickActionFloatBlock,
    analyticsToggleServices,
    analyticsEnableService,
    analyticsDisableService,
    analyticsEnterPrompt,
    analyticsSubmitPrompt,
    analyticsSubmitPayForm,
  };
});
