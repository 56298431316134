import { defineStore } from 'pinia';
import { FetchError } from 'ofetch';
import { getUser as getUserApi } from '~/api/user';
import { useAuthStore } from '~/stores/AuthStore';

export const useUserStore = defineStore('user', () => {
  const user = ref();
  const getUser = async () => {
    try {
      user.value = await getUserApi();
    } catch (e) {
      if (e instanceof FetchError) {
        if (e.status == 401) {
          useAuthStore().logout();
        }
        if (e.status == 500) {
          useAuthStore().logout();
        }
      }
    }
  };
  return {
    user,
    getUser,
  };
});
