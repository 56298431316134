import { useUserStore } from '~/stores/UsersStore';
import { useAuthStore } from '~/stores/AuthStore';

export default defineNuxtRouteMiddleware(async (to) => {
  const userStore = useUserStore();
  const authStore = useAuthStore();

  const token = to.query.token;
  if (!authStore.authToken && token?.length) {
    authStore.loginByToken(token as string);
    return navigateTo({ ...to, query: {} }, { replace: true });
  }

  if (authStore.authToken && !userStore.user) {
    await userStore.getUser();
  }
  // 'projects-id',
  if (!['topics', 'topics-slug', 'projects-id', 'index', 'yandex', 'vk', 'course-work', 'referat', 'text'].includes(<string>to.name)) {
    if (!userStore.user) {
      // return navigateTo('/login');
      return navigateTo('/');
    }
  }
  // else if (to.path === '/login' && userStore.user) {
  //     // return navigateTo('/lk');
  //     authStore.logout();
  //     // location.reload();
  //     return navigateTo('/enrollment-over');
  // }
});
