import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack';
import { AuthStatus } from '~/stores/AuthStore';

export const authFetch = <Type>(request: NitroFetchRequest, opts: NitroFetchOptions<any> = {}) => {
  const authStore = useAuthStore();
  opts.headers =
    authStore.status == AuthStatus.AUTHORIZED
      ? {
          ...opts.headers,
          Authorization: `Bearer ${authStore.authToken}`,
        }
      : opts.headers;

  return $fetch<Type>(request, opts);
};
