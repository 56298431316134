import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import ab_45global from "/app/src/middleware/ab.global.ts";
import auth_45global from "/app/src/middleware/auth.global.ts";
import utm_45global from "/app/src/middleware/utm.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  ab_45global,
  auth_45global,
  utm_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  slug: () => import("/app/src/middleware/slug.ts")
}