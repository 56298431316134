<template>
  <transition
    name="modal"
    @after-leave="handleAfterLeave"
  >
    <div
      v-if="d_show"
      class="modal"
      @mousedown.self="close"
    >
      <div
        class="modal__container"
        :style="[sizeStyle]"
      >
        <button
          v-if="closable"
          class="modal__close-button"
          type="button"
          @click="close"
        >
          <i class="icon-close" />
        </button>
        <div class="modal__body">
          <h3
            v-if="title"
            class="modal__title"
          >
            {{ title }}
          </h3>
          <slot />
          <div
            v-if="$slots.footer"
            class="modal__footer"
          >
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'VModal',
  props: {
    title: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
    height: {
      type: String,
      default: null,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    overflow: {
      type: String,
      default: 'hidden',
    },
  },
  emits: ['open', 'close'],
  data() {
    return {
      d_show: false,
    };
  },
  computed: {
    sizeStyle() {
      return {
        width: this.width,
        height: this.height,
        overflow: this.overflow,
      };
    },
  },
  mounted() {
    document.body.appendChild(this.$el);
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = scrollBarWidth + 'px';
    if (window.innerWidth > 1024) {
      document.querySelector('.navbar').style.paddingRight = scrollBarWidth + 'px';
    }
    document.documentElement.classList.toggle('modal-opened', true);
    this.d_show = true;
    this.$emit('open');
  },
  unmounted() {
    if (this.$el.parentElement) {
      this.$el.parentElement.removeChild(this.$el);
    }
    if (document.getElementsByClassName('modal').length === 0) {
      document.documentElement.classList.toggle('modal-opened', false);
      document.body.style.paddingRight = '0';
      if (window.innerWidth > 1024) {
        document.querySelector('.navbar').style.paddingRight = '0';
      }
    }
  },
  methods: {
    handleAfterLeave() {
      if (document.getElementsByClassName('modal').length === 0) {
        document.documentElement.classList.toggle('modal-opened', false);
        document.body.style.paddingRight = '0';
      }
      this.$emit('close');
    },
    close() {
      if (this.closable) {
        this.d_show = false;
      }
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.modal-opened {
  overflow-y: hidden;

  .sticky-menu__content {
    padding-right: 14px;
  }
}

.modal {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(black, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__container {
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-height: 90%;
  min-width: 300px;
  position: relative;
  margin: 0 auto;
  background: #fff;
  width: 580px;
  max-width: 95%;
  color: $dark;
  padding: 25px;

  @include media-breakpoint-down(md) {
    width: 100% !important;
    height: 100% !important;
    max-height: 100%;
    max-width: 100%;
    border-radius: 0;
  }
}

.modal__close-button {
  position: absolute;
  z-index: 1;
  outline: none;
  font-weight: 100;
  cursor: pointer;
  line-height: 18px;
  padding: 0;
  margin: 5px;
  width: 46px;
  height: 46px;
  right: 0;
  top: 0;
  opacity: 1;
  transition: all 0.1s;
  flex-shrink: 0;
  background: transparent;
  border: none;
  font-size: 30px;
  color: $primary;

  &:hover {
    color: rgba($primary, 0.8);
  }

  &:focus {
    outline: none;
    transform: scale(0.8);
  }

  @include media-breakpoint-down(md) {
    transform: none;
    z-index: 1;
  }

  &.dark {
    color: $dark;

    &:hover {
      color: rgba($dark, 0.8);
    }
  }
}
.modal__title {
  font-size: 28px;
  margin-bottom: 14px;
  text-align: center;
}
.modal__footer {
  min-height: 60px;
  background: $primary;
  color: white;
  margin: 0 -80px;
  padding: 0 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-top: 50px;
  margin-bottom: -25px;
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>
