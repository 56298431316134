import { endpoint } from '~/api/auth';
import { authFetch } from '~/api/utils';
import type { Project } from '~/stores/Project.types';
export const getUser = async (): Promise<any> => {
  return await authFetch(endpoint + 'user');
};

export const getUserProjects = async (): Promise<Project[]> => {
  return await authFetch(endpoint + 'user/projects/', {
    method: 'GET',
  });
};
