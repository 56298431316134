<template>
  <button
    class="button"
    :class="c_class"
    :style="c_style"
    :type="type"
    :disabled="disabled"
  >
    <span
      v-if="$slots.icon"
      class="button__icon"
    >
      <slot name="icon" />
    </span>
    <span
      v-if="loading"
      class="button__loader"
    >
      <VSpinner :color="c_spinnerColor" />
    </span>
    <slot />
  </button>
</template>

<script>
import VSpinner from '~/components/common/VSpinner.vue';

export default {
  name: 'VButton',
  components: { VSpinner },
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: String,
      default: 'primary',
    },
    type: {
      type: String,
      default: null,
    },
    background: {
      type: String,
      default: null,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    square: {
      type: [Number, String],
      default: null,
    },
  },
  computed: {
    c_class() {
      const res = ['button_' + this.schema];
      if (this.outline) {
        res.push('button_outline');
      }
      if (this.square) {
        res.push('button_square');
      }
      if (this.disabled) {
        res.push('button_disabled');
      }

      if (this.secondary) {
        res.push('button_secondary');
      }
      if (this.loading) {
        res.push('button_loading');
      }
      return res.join(' ');
    },
    c_style() {
      const square = this.square
        ? {
            width: this.square + 'px',
            height: this.square + 'px',
          }
        : {};
      const background = this.background
        ? {
            background: this.background,
            border: '1px solid ' + this.background,
          }
        : {};

      return { ...square, ...background };
    },
    c_spinnerColor() {
      if (this.schema === 'secondary') {
        return '#2042B9';
      }
      return '#fff';
    },
  },
};
</script>

<style lang="scss">
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  padding: 14px 25px;
  border: none;
  position: relative;
  border-radius: 10px;
  //min-height: 46px;
  transition: 0.25s;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none !important;
  //transition: background-size .3s;
  @include media-breakpoint-down(md) {
    padding: 14px 18px;
    font-size: 16px;
  }

  &.button_disabled {
    pointer-events: none;
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }

  &:active {
    //@include media-breakpoint-up(md) {
    //    transform: scale(0.97);
    //}
  }

  .icon-animate-magic1 {
    animation: rotate 1.5s infinite;
    transform-origin: center;
  }

  .icon-animate-magic2 {
    animation: blink 1.5s infinite;
    transform-origin: center;
    animation-delay: 0.3s;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg) scale(0.5);
      opacity: 0;
    }
    50% {
      transform: rotate(180deg) scale(1);
      opacity: 1;
    }
    100% {
      transform: rotate(360deg) scale(0.5);
      opacity: 0;
    }
  }
  @keyframes blink {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    50% {
      transform: scale(1.3);
      opacity: 1;
    }
    100% {
      transform: scale(0.5);
      opacity: 0;
    }
  }
}

.button_text {
  padding: 0;
  color: $primary;
  font-weight: 600;
  background: transparent;
}

.button_icon {
  background: transparent;
  padding: 0;

  &-danger {
    background: $danger;
    color: white !important;
    font-size: 30px;
    padding: 3px;
    @include media-breakpoint-down(lg) {
      font-size: 20px;
    }

    &:hover {
      //transform: scale(1.02);
      background: rgba($danger, 0.7);
    }
  }
}

.button_primary {
  background: $primary-gradient;
  color: white;
  background-size: 100% 100%;

  &:hover {
    box-shadow: $shadow;
    background-size: 150% 150%;
    @include media-breakpoint-down(md) {
      box-shadow: none;
    }
  }

  &.button_disabled {
    border: 1px solid lighten($primary, 30%);
    background: lighten($primary, 30%);
  }

  &.button_outline {
    background: transparent;
    color: $primary;
    font-weight: 400;
    border: 1px solid $primary;

    &:hover {
      background: transparent;
    }

    &:active {
      transform: scale(0.99);
      color: lighten($primary, 30%);
      border: 1px solid lighten($primary, 30%);
    }
  }
}

.button_black {
  background: $dark;
  font-weight: 700;
  color: white;

  &:hover {
    box-shadow: $shadow;
  }
  &:active {
    transform: scale(0.97);
  }
  &.button_outline {
    background: transparent;
    color: $dark;
    font-weight: 400;
    border: 1px solid $dark;

    &:hover {
      background: transparent;
      color: lighten($dark, 20%);
      border: 1px solid lighten($dark, 20%);
      @include media-breakpoint-down(md) {
        color: $dark;
        border: 1px solid $dark;
      }
    }
  }
}

.button_success {
  background: $success;
  color: white;

  &:hover {
    box-shadow: $shadow;
  }

  &.button_outline {
    background: transparent;
    color: $success;
    font-weight: 400;
    border: 1px solid $success;

    &:hover {
      background: transparent;
      color: darken($success, 7%);
      border: 1px solid darken($success, 7%);
      @include media-breakpoint-down(md) {
        color: $success;
        border: 1px solid $success;
      }
    }
  }
}

.button_primary-light {
  background: $gradient-light;
  font-weight: 600;
  color: white;
}

.button_secondary {
  background: $secondary;
  color: $dark;
  font-weight: 500;

  &:hover {
    border: 1px solid darken($secondary, 7%);
    background: darken($secondary, 7%);
  }

  &.button_disabled {
    border: 1px solid lighten($secondary, 30%);
    background: lighten($secondary, 30%);
  }

  &.button_outline {
    background: transparent;
    color: $secondary;
    font-weight: 400;

    &:hover {
      background: transparent;
      color: rgba($secondary, 0.7);
      border: 1px solid rgba($secondary, 0.7);
    }
  }
}

.button_dashed {
  background: transparent;
  color: $secondary;
  font-size: 18px;
  font-weight: 600;
  //border: 1px dashed $secondary;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%238C75FFFF' stroke-width='3' stroke-dasharray='8' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e");
  @include media-breakpoint-down(md) {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%238C75FFFF' stroke-width='3' stroke-dasharray='8' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e");
  }
  @include media-breakpoint-down(sm) {
    border-radius: 8px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%238C75FFFF' stroke-width='3' stroke-dasharray='8' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e");
  }

  &:hover {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%238C75FFB3' stroke-width='3' stroke-dasharray='8' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e");
    color: rgba($secondary, 0.7);
    @include media-breakpoint-down(md) {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%238C75FFB3' stroke-width='3' stroke-dasharray='8' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e");
    }
    @include media-breakpoint-down(sm) {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%238C75FFB3' stroke-width='3' stroke-dasharray='8' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e");
    }
  }
}

.button_white {
  background: white;
  color: $dark !important;
}

.button_transparent {
  background: none;
}

.button_light {
  background: $light;
  color: #7132c5;

  &.button_outline {
    background: transparent;
    color: $light;
    border: 1px solid $light;
  }
}

.button_danger {
  background: $danger;
  color: white !important;

  &.button_outline {
    background: transparent;
    color: $danger !important;
    border: 1px solid $danger;
  }
}

.button_gray {
  background: $gray;
  color: $dark;

  &.button_outline {
    background: transparent;
    border: 1px solid $gray;
  }
}

.button_square {
  min-height: auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 16px;
}

.button__loader {
  display: block;
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  height: 2em;
}

.button_loading {
  opacity: 0.8;
}
</style>
