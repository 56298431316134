import { defineStore } from 'pinia';
import { ref } from 'vue';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from 'axios';
import { login as loginApi, register as registerApi, loginByYandex as loginByYandexApi, loginByVK as loginByVKApi } from '~/api/auth';

export enum AuthStatus {
  UNAUTHORIZED,
  AUTHORIZED,
}

export const useAuthStore = defineStore('auth', () => {
  const tokenCookie = useCookie('token', { maxAge: 86400 * 365 });
  const email = ref('');
  const password = ref('');
  const name = ref('');
  const passwordConfirmation = ref('');
  const authError = ref('');
  const authToken = ref<string>(tokenCookie.value as string);
  const status = ref<AuthStatus>(authToken.value ? AuthStatus.AUTHORIZED : AuthStatus.UNAUTHORIZED);

  const login = async () => {
    try {
      const result = await loginApi(email.value, password.value);
      authToken.value = result.token;
      tokenCookie.value = result.token;
      status.value = AuthStatus.AUTHORIZED;
    } catch (e: AxiosError | any) {
      authError.value = e.response._data.message || 'Произошла ошибка';
    }
  };

  const loginByYandex = async (token: string) => {
    try {
      const result = await loginByYandexApi(token);
      authToken.value = result.token;
      tokenCookie.value = result.token;
      status.value = AuthStatus.AUTHORIZED;
    } catch (e: AxiosError | any) {
      authError.value = e.response._data.message || 'Произошла ошибка';
    }
  };

  const loginByVK = async (payload: string) => {
    try {
      const result = await loginByVKApi(payload);
      authToken.value = result.token;
      tokenCookie.value = result.token;
      status.value = AuthStatus.AUTHORIZED;
    } catch (e: AxiosError | any) {
      authError.value = e.response._data.message || 'Произошла ошибка';
    }
  };

  const loginByToken = (token: string) => {
    try {
      authToken.value = token;
      tokenCookie.value = token;
      status.value = AuthStatus.AUTHORIZED;
    } catch (e: AxiosError | any) {
      authError.value = e.response._data.message || 'Произошла ошибка';
    }
  };

  const register = async () => {
    try {
      const result = await registerApi(name.value, email.value, password.value, password.value);
      authToken.value = result.token;
      tokenCookie.value = result.token;
      status.value = AuthStatus.AUTHORIZED;
    } catch (e: AxiosError | any) {
      authError.value = e.response._data.message || 'Произошла ошибка';
    }
  };

  const logout = () => {
    tokenCookie.value = null;
    password.value = '';
    email.value = '';
    authToken.value = '';
    status.value = AuthStatus.UNAUTHORIZED;
  };

  return {
    login,
    register,
    email,
    name,
    passwordConfirmation,
    password,
    status,
    authError,
    authToken,
    logout,
    loginByYandex,
    loginByVK,
    loginByToken,
  };
});
