import { endpoint } from '~/api/auth';
import { authFetch } from '~/api/utils';

export default defineNuxtRouteMiddleware((to) => {
  const analyticsStore = useAnalyticsStore();
  if (import.meta.client) {
    authFetch(`${endpoint}ab`);
    analyticsStore.analyticsPageOpen(to.name as string);
  }
});
