import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp;

    Sentry.init({
      app: vueApp,
      dsn: 'https://6ad6b478cfe48de4f947a6d908d46d36@o4503993548603392.ingest.sentry.io/4506667749212160',
      tracesSampleRate: 1,
    });
  },
});
