<script setup lang="ts"></script>

<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__content">
        <div class="footer__logo-wrap">
          <nuxt-link
            to="/#top"
            class="footer__logo"
          >
            Бегемот
          </nuxt-link>
        </div>
        <ul class="footer__links">
          <li>
            <nuxt-link
              to="#last-projects"
              class="footer__link"
            >
              Последние проекты
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              to="#faq"
              class="footer__link"
            >
              FAQ
            </nuxt-link>
          </li>
        </ul>
        <div class="footer__tg">
          <nuxt-link
            to="https://t.me/projects_ai"
            target="_blank"
            rel="noopener noreferrer"
          >
            @projects_ai
            <i class="icon-telegram" />
          </nuxt-link>
          <!--                    <nuxt-link to="https://t.me/"-->
          <!--                               target="_blank"-->
          <!--                               rel="noopener noreferrer"-->
          <!--                    >-->
          <!--                        @projectai_support-->
          <!--                        <i class="icon-support" />-->
          <!--                    </nuxt-link>-->
        </div>
        <div class="footer__info">
          <div class="footer__docs">
            <a
              class="link link_light"
              href="/termofuse.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span class="text-decoration-underline">Пользовательское соглашение</span>
            </a>
            <a
              class="link link_light"
              href="/offer.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span class="text-decoration-underline">Публичная оферта</span>
            </a>
            <a
              class="link link_light"
              href="/privacy.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span class="text-decoration-underline">Политика конфиденциальности</span>
            </a>
          </div>
          <p>г. Москва, ул. Краснопролетарская, д. 7, помещение 4/п</p>
          <p>ОГРН 1247700367452</p>
          <p>ИНН 9707030430</p>
          <div class="footer__contacts">
            <nuxt-link
              to="https://t.me/projects_ai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="icon-telegram" />
            </nuxt-link>
          </div>
        </div>
      </div>
      <div class="footer__copyright">ООО Бегемот ЭйАй © 2024</div>
    </div>
  </footer>
</template>

<style lang="scss">
.footer {
  background: url('/images/footer-bg.svg') center top no-repeat;
  background-size: cover;
  padding: 70px 0 9px;
  color: white;
  @include media-breakpoint-down(lg) {
    padding: 100px 0 9px;
  }
  @include media-breakpoint-down(sm) {
    margin-top: -40px;
  }

  .footer__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-down(lg) {
      grid-gap: 20px;
    }
    @include media-breakpoint-down(md) {
      grid-gap: 16px;
      text-align: center;
      grid-template-columns: auto;
      justify-content: center;
      margin-bottom: 16px;
    }

    .footer__logo {
      line-height: 150%;
      color: white;
      font-size: 24px;
      font-weight: 900;
      display: inline-block;
      @include media-breakpoint-down(xl) {
        font-size: 16px;
      }
    }

    .footer__tg {
      display: none;
      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        a {
          font-size: 18px;
          padding: 12px 20px;
          border-radius: 100px;
          background: $dark;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;

          i {
            font-size: 25px;
            margin-left: 8px;
          }
        }
      }
      @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        a {
          font-size: 14px;
          padding: 12px 16px;
          border-radius: 100px;
          background: $dark;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;

          i {
            font-size: 20px;
            margin-left: 8px;
          }
        }
      }
    }

    .footer__links {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 40px;

      .footer__link {
        font-size: 18px;
        color: white;
        @include media-breakpoint-down(xl) {
          font-size: 16px;
        }
        @include media-breakpoint-down(md) {
          font-size: 13px;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      @include media-breakpoint-down(xl) {
        flex-direction: column;
        gap: 16px;
      }
    }

    .footer__contacts {
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      @include media-breakpoint-down(md) {
        display: none;
      }

      a {
        font-size: 35px;
        color: white;
        transition: 0.3s;
        @include media-breakpoint-down(lg) {
          font-size: 26px;
        }

        &:hover {
          transform: scale(1.05);
        }
      }
    }

    .footer__info {
      font-weight: 300;
      text-align: right;
      font-size: 14px;

      .footer__docs {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-bottom: 16px;

        a {
          display: inline;
          width: auto;
        }

        @include media-breakpoint-down(xl) {
          font-size: 14px;
        }
        @include media-breakpoint-down(md) {
          text-align: center;
        }
        @include media-breakpoint-down(md) {
          font-size: 12px;
        }
      }
    }
  }

  .footer__copyright {
    font-size: 14px;
    text-align: center;
    font-weight: 300;
    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
  }
}
</style>
