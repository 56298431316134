<script setup lang="ts">
import VButton from '~/components/common/form/VButton.vue';

import AuthModal from '~/components/site/auth/AuthModal.vue';
import { useUserStore } from '~/stores/UsersStore';
import { AuthStatus, useAuthStore } from '~/stores/AuthStore';
import VModal from '~/components/common/VModal.vue';
import VDropdown from '~/components/common/VDropdown.vue';
import { getUserProjects } from '~/api/user';

const userStore = useUserStore();

const authStore = useAuthStore();

authStore.$subscribe(async () => {
  if (authStore.status === AuthStatus.AUTHORIZED) {
    await userStore.getUser();
  }
});
const props = defineProps({
  schema: {
    type: String,
    default: null,
  },
});
const cClass = computed(() => {
  const res = [];
  if (props.schema) {
    res.push('navbar_' + props.schema);
  }
  return res.join(' ');
});
const navSticky = ref<boolean>(false);
const showAuth = ref(false);
const showMenu = ref(false);
const showRegister = ref(false);

onMounted(() => {
  if (window && window.ym && userStore.user) {
    window.ym(95948387, 'userParams', {
      id: userStore.user.id,
      email: userStore.user.email,
    });
  }
  if (window) {
    document.addEventListener('scroll', onScroll, { passive: true });
  }
  if (window && window.jivo_api && userStore.user) {
    setTimeout(async () => {
      window.jivo_api.setContactInfo({
        name: userStore.user.name,
        email: userStore.user.email,
        phone: userStore.user.phone,
        description: `https://begemot.ai/nova/resources/users/${userStore.user.id}`,
      });

      const projects = await getUserProjects();
      const data = projects.slice(0, 10).map((p) => ({
        key: `#${p.id}`,
        link: `https://begemot.ai/projects/${p.id}`,
        title: p.title,
        content: p.payed_status ? `Оплачен: ${(p.services || []).join(',')}` : 'Не оплачен',
      }));
      window.jivo_api.setCustomData(data);
    }, 5000);
  }
});

onBeforeUnmount(() => {
  if (window) {
    document.removeEventListener('scroll', onScroll);
  }
});

function onScroll() {
  const rect = window.scrollY;
  navSticky.value = rect > 10;
}

const route = useRoute();

function logout() {
  authStore.logout();
  userStore.user = undefined;
  location.reload();
}
</script>

<template>
  <header
    id="top"
    class="header"
    :class="{ navbar_sticky: navSticky }"
  >
    <nav
      ref="nav"
      class="navbar"
      :class="cClass"
    >
      <div class="container">
        <div class="navbar__content">
          <div class="navbar__logo-wrap">
            <nuxt-link
              to="/"
              class="navbar__logo"
            >
              <h1 v-if="route.name === 'index'">Нейросеть для проекта</h1>
              <h1 v-else-if="route.name === 'course-work'">Нейросеть для курсовой</h1>
              <h1 v-else-if="route.name === 'referat'">Нейросеть для реферата</h1>
              <h1 v-else-if="route.name === 'text'">Нейросеть для текста</h1>
              <template v-else> Нейросеть для проекта </template>
            </nuxt-link>
          </div>
          <v-button
            class="navbar__menu-button"
            schema="icon"
            @click="showMenu = true"
          >
            <i class="icon-menu" />
          </v-button>
          <div class="navbar__group">
            <ul class="navbar__links">
              <li v-if="userStore.user">
                <nuxt-link
                  to="/profile"
                  class="navbar__link"
                  @click="showMenu = false"
                >
                  Мои заказы
                </nuxt-link>
              </li>
              <li v-else>
                <nuxt-link
                  to="/#last-projects"
                  class="navbar__link"
                >
                  Последние проекты
                </nuxt-link>
              </li>
              <li>
                <nuxt-link
                  to="/topics"
                  class="navbar__link"
                >
                  Темы проектов
                </nuxt-link>
              </li>
              <li>
                <nuxt-link
                  to="/#faq"
                  class="navbar__link"
                >
                  FAQ
                </nuxt-link>
              </li>
            </ul>

            <div class="navbar__buttons">
              <v-dropdown
                v-if="userStore.user"
                class="navbar__dropdown"
              >
                <v-button
                  class="navbar__profile-btn"
                  schema="light"
                >
                  {{ userStore.user.name[0] || 'U' }}
                </v-button>
                <template #body>
                  <ul>
                    <!-- <li v-if="userStore.user">-->
                    <!--     <v-button schema="white"-->
                    <!--               @click="navigateTo('/profile')"-->
                    <!--     >-->
                    <!--         <i class="icon-profile" /> Мои проекты-->
                    <!--     </v-button>-->
                    <!-- </li>-->
                    <li>
                      <v-button
                        schema="white"
                        @click="logout()"
                      >
                        <i class="icon-logout" />Выйти
                      </v-button>
                    </li>
                  </ul>
                </template>
              </v-dropdown>
              <template v-else>
                <v-button
                  schema="light"
                  :outline="true"
                  @click="
                    showAuth = true;
                    showRegister = false;
                  "
                >
                  Войти
                </v-button>
                <v-button
                  schema="light"
                  @click="
                    showAuth = true;
                    showRegister = true;
                  "
                >
                  Регистрация
                </v-button>
              </template>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
  <AuthModal
    v-if="showAuth"
    :is-register="showRegister"
    @close-auth="
      showAuth = false;
      showRegister = false;
    "
  />
  <v-modal
    v-if="showMenu"
    class="menu-modal"
    @close="showMenu = false"
  >
    <div class="navbar__logo-wrap">
      <nuxt-link
        to="/"
        class="navbar__logo"
        @click="showMenu = false"
      >
        <h1 v-if="route.name === 'index'">Нейросеть для проекта</h1>
        <template v-else> Нейросеть для проекта </template>
      </nuxt-link>
    </div>
    <div class="navbar__group">
      <ul class="navbar__links">
        <li>
          <nuxt-link
            v-if="userStore.user"
            to="/profile"
            class="navbar__link"
            @click="showMenu = false"
          >
            Мои заказы
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            to="/#last-projects"
            class="navbar__link"
            @click="showMenu = false"
          >
            Последние проекты
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            to="/#faq"
            class="navbar__link"
            @click="showMenu = false"
          >
            FAQ
          </nuxt-link>
        </li>
      </ul>
      <div class="navbar__buttons">
        <v-button
          v-if="userStore.user"
          :outline="true"
          @click="
            showMenu = false;
            logout();
          "
        >
          <i class="icon-logout mx-2" /> Выйти
        </v-button>
        <template v-else>
          <v-button
            :outline="true"
            @click="
              showMenu = false;
              showAuth = true;
              showRegister = false;
            "
          >
            Войти
          </v-button>
          <v-button
            @click="
              showMenu = false;
              showAuth = true;
              showRegister = true;
            "
          >
            Регистрация
          </v-button>
        </template>
      </div>
    </div>
  </v-modal>
</template>

<style lang="scss">
.header {
  &.navbar_sticky {
    .navbar {
      background: linear-gradient(90deg, rgba(106, 98, 234, 1) 0%, rgba(158, 98, 234, 1) 100%);
      padding: 16px 0;
      box-shadow: 0 1px 10px #00000017;
      //background-color: rgba(255, 255, 255, 0.7);

      .navbar__logo img {
        width: 70px;
      }
    }
  }
}

.menu-modal {
  .modal__container {
    padding: 16px 10px;
  }

  .navbar__logo {
    background: -webkit-linear-gradient(270deg, #6a62ea 0%, #9e62ea 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 30px;

    h1 {
      margin-bottom: 0;
    }
  }

  .navbar__links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .navbar__link {
      font-weight: 500;
      color: black;
      font-size: 16px;
      padding: 10px 0;
      display: block;
    }
  }

  .navbar__group {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .navbar__buttons {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;

    .icon-logout {
      font-size: 25px;
    }

    button {
      min-height: 50px;
    }
  }
}

.navbar__logo {
  display: flex;
  color: white;
  font-size: 24px;
  font-weight: 900;
  line-height: 150%;

  h1 {
    font-size: 24px;
    margin-bottom: 0;
  }

  @include media-breakpoint-down(lg) {
    font-size: 18px;
    h1 {
      font-size: 18px;
    }
  }

  &:hover {
    color: white;
  }

  .navbar__logo-img {
    margin-right: 12px;
    @include media-breakpoint-down(xxl) {
      width: 40px;
    }
    @include media-breakpoint-down(md) {
      width: 34px;
      margin-right: 8px;
    }
  }
}

.navbar {
  z-index: 1000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  transition: backdrop-filter 0.2s, padding-top 0.2s, padding-bottom 0.2s;
  //backdrop-filter: blur(10px);
  padding: 20px 0;
  @include media-breakpoint-down(md) {
    padding: 16px 0;
  }

  &_primary {
    background: linear-gradient(90deg, #6a62ea 0%, #9e62ea 100%);
  }

  .navbar__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .navbar__menu-button {
    display: none;
    @include media-breakpoint-down(md) {
      display: block;
      color: white;
      font-size: 30px;
    }
  }

  .navbar__links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 30px;
    align-items: center;
    @include media-breakpoint-down(lg) {
      gap: 24px;
    }

    .navbar__link {
      color: white;
      font-size: 16px;

      @include media-breakpoint-down(lg) {
        font-size: 13px;
      }
    }
  }

  .navbar__group {
    display: flex;
    gap: 30px;
    @include media-breakpoint-down(lg) {
      gap: 24px;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .navbar__buttons {
    display: flex;
    gap: 12px;

    .navbar__dropdown {
      .button_white {
        border-radius: 0;
        width: 100%;
        min-width: 200px;
        text-align: left;
        justify-content: flex-start;
        padding: 12px 16px;

        i {
          font-size: 25px;
          margin-right: 8px;
        }

        &:hover {
          background: $light;
        }
      }

      .navbar__profile-btn {
        border-radius: 100px;
        padding: 0;
        width: 40px;
        height: 40px;
        text-transform: uppercase;

        &:hover {
          background: rgba($light, 0.8);
        }
      }
    }

    button {
      border-radius: 12px;
      padding: 12px 30px;
      @include media-breakpoint-down(lg) {
        padding: 8px 16px;
        font-size: 13px;
      }
    }
  }
}
</style>
