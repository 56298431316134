<script setup lang="ts">
import VButton from '~/components/common/form/VButton.vue';
import VHeader from '~/components/site/index/VHeader.vue';
import VFooter from '~/components/site/index/VFooter.vue';

const props = defineProps({
  error: {
    type: Object,
    default() {
      return {};
    },
  },
});
const handleError = () => clearError({ redirect: '/' });
//
console.log(props.error);
</script>
<template>
  <div class="error-page">
    <v-header schema="primary" />
    <div class="container">
      <div class="error-content">
        <template v-if="error.statusCode == 404">
          <h1>Упс! Такой страницы не существует</h1>
          <p>Возможно, страница была удалена или в запросе был указан неверный адрес.</p>
          <nuxt-link
            class="link-button link-button_outline"
            to="/"
          >
            На главную
          </nuxt-link>
        </template>
        <template v-else-if="error.statusCode == 502">
          <h1>Выполняется обновление...</h1>
          <p>Попробуйте обновить страницу или зайти через несколько минут.</p>
        </template>
        <template v-else>
          <h1>Упс! Что-то пошло не так...</h1>
          <p>Мы знаем о проблеме и уже работаем. Попробуйте обновить страницу или зайти попозже.</p>
          <v-button
            schema="primary"
            class="button_outline"
            @click="handleError"
          >
            На главную
          </v-button>
        </template>
      </div>
    </div>
    <v-footer />
  </div>
</template>
<style lang="scss">
.error-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 100px;

  footer {
    width: 100%;
  }

  .error-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    img {
      //width: 400px;
      margin-bottom: 30px;
    }

    h1 {
      margin-bottom: 12px;
      line-height: 120%;
    }

    p {
      font-size: 15px;
      line-height: 150%;
      margin-bottom: 24px;
    }
  }
}
</style>
