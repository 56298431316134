<script setup lang="ts">
import { useAuthStore } from '~/stores/AuthStore';
import VButton from '~/components/common/form/VButton.vue';
import VModal from '~/components/common/VModal.vue';
import YandexAuthButton from '~/components/site/auth/YandexAuthButton.vue';
import VkAuthButton from '~/components/site/auth/VkAuthButton.vue';

const props = defineProps({
  isRegister: {
    type: Boolean,
    default: false,
  },
});
const showPassword = ref<boolean>(false);
const showRegister = ref<boolean>(props.isRegister);
const authStore = useAuthStore();
const emit = defineEmits(['closeAuth']);

async function register() {
  await authStore.register();
  if (authStore.authToken) {
    authStore.authError = '';
  }
  if (!authStore.authError) {
    emit('closeAuth');
  }
}

async function login() {
  await authStore.login();
  if (authStore.authToken) {
    authStore.authError = '';
  }
  if (!authStore.authError) {
    emit('closeAuth');
  }
}

function clearAll() {
  authStore.authError = '';
  authStore.name = '';
  authStore.email = '';
  authStore.password = '';
}
</script>

<template>
  <v-modal
    :title="showRegister ? 'Регистрация' : 'Вход'"
    @close="
      showRegister = false;
      $emit('closeAuth');
    "
  >
    <form
      v-if="showRegister"
      class="auth"
      @submit.prevent="register()"
    >
      <p>
        Уже есть аккаунт?
        <v-button
          schema="text"
          type="button"
          @click="
            showRegister = false;
            clearAll();
          "
        >
          Войдите
        </v-button>
      </p>
      <label>
        <input
          v-model="authStore.name"
          required
          type="text"
          autocomplete="name"
          autofocus
          placeholder="Ваше имя"
        />
      </label>
      <label>
        <input
          v-model="authStore.email"
          required
          type="email"
          autocomplete="new-email"
          autofocus
          placeholder="Ваша почта"
        />
      </label>
      <label class="password-label">
        <input
          v-model="authStore.password"
          required
          autocomplete="new-password"
          :type="showPassword ? 'text' : 'password'"
          placeholder="Пароль"
        />
        <i
          class="link link_gray input__icon"
          role="button"
          :class="showPassword ? 'icon-hide' : 'icon-show'"
          @click="showPassword = !showPassword"
        />
      </label>
      <div
        v-if="authStore.authError"
        class="error mb-3"
      >
        {{ authStore.authError }}
      </div>
      <v-button type="submit"> Зарегистрироваться </v-button>
    </form>
    <form
      v-else
      class="auth"
      @submit.prevent="login()"
    >
      <p>
        Нет аккаунта?
        <v-button
          schema="text"
          type="button"
          @click="
            showRegister = true;
            clearAll();
          "
        >
          Зарегистрируйтесь
        </v-button>
      </p>
      <label>
        <input
          v-model="authStore.email"
          required
          type="email"
          autofocus
          placeholder="Ваша почта"
        />
      </label>
      <label class="password-label">
        <input
          v-model="authStore.password"
          required
          autocomplete="on"
          :type="showPassword ? 'text' : 'password'"
          placeholder="Пароль"
        />
        <i
          class="link link_gray input__icon"
          role="button"
          :class="showPassword ? 'icon-hide' : 'icon-show'"
          @click="showPassword = !showPassword"
        />
      </label>
      <div
        v-if="authStore.authError"
        class="error mb-3"
      >
        {{ authStore.authError }}
      </div>
      <v-button type="submit"> Войти </v-button>
    </form>
    <YandexAuthButton @close-auth="$emit('closeAuth')" />
    <VkAuthButton />
  </v-modal>
</template>

<style scoped lang="scss">
.auth {
  p {
    font-size: 16px;
    margin-bottom: 22px;
    text-align: center;
  }

  .error {
    color: $danger;
  }

  .password-label {
    position: relative;

    input {
      width: 100%;
    }
  }

  label {
    margin-bottom: 12px;
    width: 100%;

    input {
      background: white;
      padding: 17px 20px;
      border-radius: 10px;
      border: 1px solid $secondary;
      width: 100%;

      &::placeholder {
        color: $secondary;
      }

      &:focus {
        outline: 2px solid $secondary;
      }
    }
  }

  .button_primary {
    width: 100%;
  }

  small {
    margin-top: 22px;
    font-size: 13px;
    line-height: 130%;
    text-align: center;
    display: block;

    a {
      text-decoration: underline;
    }
  }
}
</style>
